import { uniqueId } from 'lodash';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import '@app/index.css';
import { BorderLayout } from '@app/components/Layout/BorderLayout';
import { ScrollToTop } from '@app/hooks/useScroll';

const DefaultLayout = lazy(() =>
  import('@app/components/Layout/DefaultLayout').then((module) => ({
    default: module.DefaultLayout,
  }))
);
const ChatList = lazy(() =>
  import('@app/views/Chat/Index').then((module) => ({ default: module.Index }))
);
const Chat = lazy(() =>
  import('@app/views/Chat/Show').then((module) => ({ default: module.Show }))
);
const ErrorFound = lazy(() =>
  import('@app/views/Error').then((module) => ({ default: module.ErrorFound }))
);
const Home = lazy(() =>
  import('@app/views/Home').then((module) => ({ default: module.Home }))
);
const LineCallback = lazy(() =>
  import('@app/views/LineCallback').then((module) => ({
    default: module.LineCallback,
  }))
);
const Login = lazy(() =>
  import('@app/views/Login').then((module) => ({ default: module.Login }))
);
const LoginCallback = lazy(() =>
  import('@app/views/LoginCallback').then((module) => ({
    default: module.LoginCallback,
  }))
);
const MockLine = lazy(() =>
  import('@app/views/MockLine').then((module) => ({ default: module.MockLine }))
);
const NotFound = lazy(() =>
  import('@app/views/NotFound').then((module) => ({ default: module.NotFound }))
);
const NotificationDetail = lazy(() =>
  import('@app/views/NotificationDetail').then((module) => ({
    default: module.NotificationDetail,
  }))
);
const OrderList = lazy(() =>
  import('@app/views/Order/Index').then((module) => ({ default: module.Index }))
);
const Order = lazy(() =>
  import('@app/views/Order/Show').then((module) => ({ default: module.Show }))
);
const ProductList = lazy(() =>
  import('@app/views/Product/Index').then((module) => ({
    default: module.Index,
  }))
);
const Product = lazy(() =>
  import('@app/views/Product/Show').then((module) => ({ default: module.Show }))
);
const ProtectedRoute = lazy(() =>
  import('@app/views/ProtectedRoute').then((module) => ({
    default: module.ProtectedRoute,
  }))
);
const SendResetPasswordEmail = lazy(() =>
  import('@app/views/SendResetPasswordEmail').then((module) => ({
    default: module.SendResetPasswordEmail,
  }))
);
const Setting = lazy(() =>
  import('@app/views/Setting').then((module) => ({ default: module.Setting }))
);
const ChangeEmail = lazy(() =>
  import('@app/views/User/ChangeEmail').then((module) => ({
    default: module.ChangeEmail,
  }))
);
const ChangePassword = lazy(() =>
  import('@app/views/User/ChangePassword').then((module) => ({
    default: module.ChangePassword,
  }))
);
const UserEdit = lazy(() =>
  import('@app/views/User/Edit').then((module) => ({ default: module.Edit }))
);
const UserEditConfirm = lazy(() =>
  import('@app/views/User/EditConfirm').then((module) => ({
    default: module.EditConfirm,
  }))
);
const UserRegister = lazy(() =>
  import('@app/views/User/Register').then((module) => ({
    default: module.Register,
  }))
);

const WaitingForEmailVerify = lazy(() =>
  import('@app/views/WaitingForEmailVerify').then((module) => ({
    default: module.WaitingForEmailVerify,
  }))
);
const WaitingForResetPassword = lazy(() =>
  import('@app/views/WaitingForResetPassword').then((module) => ({
    default: module.WaitingForResetPassword,
  }))
);

export function App() {
  return (
    <Router>
      <Suspense fallback={null}>
        <ScrollToTop />
        <DefaultLayout>
          <Routes>
            <Route index element={<Home />} />
            <Route element={<BorderLayout />}>
              <Route path="/register" element={<UserRegister />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/send_reset_password_email"
                element={<SendResetPasswordEmail />}
              />
              <Route
                path="/waiting_reset_password"
                element={<WaitingForResetPassword />}
              />
            </Route>

            <Route
              element={
                <ProtectedRoute>
                  <BorderLayout width="664px" />
                </ProtectedRoute>
              }
            >
              <Route path="/profile/edit" element={<UserEdit />} />
              <Route
                path="/profile/edit-confirm"
                element={<UserEditConfirm />}
              />
            </Route>
            <Route
              path="/login-callback/:provider"
              element={<LoginCallback />}
            />
            <Route
              path="/setting"
              element={
                <ProtectedRoute>
                  <Setting />
                </ProtectedRoute>
              }
            />

            <Route
              path="/change-email"
              element={
                <ProtectedRoute>
                  <ChangeEmail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/change-password"
              element={
                <ProtectedRoute>
                  <ChangePassword />
                </ProtectedRoute>
              }
            />
            <Route path="/home" element={<Home />} />
            <Route
              path="/notifications"
              element={
                <ProtectedRoute>
                  <NotificationDetail />
                </ProtectedRoute>
              }
            />
            <Route path="/search/services" element={<ProductList />} />
            <Route
              path="/favorite/services"
              element={
                <ProtectedRoute>
                  <ProductList favoriteSearch={true} key={uniqueId()} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/waiting-email-verify"
              element={<WaitingForEmailVerify />}
            />
            <Route path="/products/:productId" element={<Product />} />
            <Route path="/orders">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <OrderList />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":orderId"
                element={
                  <ProtectedRoute>
                    <Order />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="/chats">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <ChatList />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":topicId"
                element={
                  <ProtectedRoute>
                    <Chat />
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* NOTE: Temporarily added */}
            <Route path="/mock-line" element={<MockLine />} />
            <Route path="/line/callback" element={<LineCallback />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/error" element={<ErrorFound />} />
          </Routes>
        </DefaultLayout>
      </Suspense>
    </Router>
  );
}
